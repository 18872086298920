import {Component, OnDestroy, OnInit} from '@angular/core';
import { authData } from './shared/helpers/auth.helper';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from "firebase/compat/app";
import {IUsers} from "./pages/auth/users.model";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Subscription} from "rxjs";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'getal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition(':enter', [   // :enter is alias for void => *
        animate('500ms ease-in', style({
          opacity: 1
        }))
      ]),
      transition(':leave', [   // :leave is alias for * => void
        animate('500ms ease-out', style({
          opacity: 0
        }))
      ])
    ])
  ]
})
export class AppComponent implements OnInit , OnDestroy {
  userForm: FormGroup;
  phoneMask = ['0', '7', '0', '-', '7', '8', '7', '-', '4', '8', '-', '4', '8'];
  isExistUser: boolean = false;
  isFirstChatBox: boolean = false;
  isSecondChatBox: boolean = false;
  isIconBtnActive: boolean = false;
  user = { fullName: '', email: '', phone: '' };
  registeredUser: any = null;
  isExistingChat: boolean = false;
  admin: any = null;
  isAdmin: boolean = false;
  userId: string = '';
  chatId: string = '';
  newMessage = '';
  conversationStarted = false;
  data: any = null;
  title = 'Udal';
  private dbPath = 'users';
  private subscribtion: Subscription | null = null;
  private loaded = 0;
  constructor(private fb: FormBuilder, private db: AngularFirestore, private auth: AngularFireAuth) {
    this.userForm = this.fb.group({
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      phone: ['', [Validators.required]]
    });
  }
  ngOnInit() {
    if (this.loaded === 0) {
      this.loaded++;
      this.authUser();
      this.checkLocalStorage();
    }
  }

  checkLocalStorage(){
    if(localStorage.getItem('isSecondChatBox') && localStorage.getItem('conversationStarted')){
      this.isSecondChatBox = true;
      this.conversationStarted = true;
      this.isIconBtnActive = true;
    }else if(localStorage.getItem('isSecondChatBox')){
      this.isSecondChatBox = true;
      this.isIconBtnActive = true;
    }
  }

  authUser() {
    return this.auth.user.subscribe((user) => {
      if (user) {
        this.subscribtion = this.db.collection<IUsers>(this.dbPath, ref => ref.where('email', '==', user.email)).valueChanges({
          idField: 'id',
          limit: 1,
        }).subscribe((data) => {
          if (data.length && data[0].id) {
            if(data[0]?.role === 'admin'){
              this.isAdmin = true;
            }
            this.isExistUser = true;
            authData.next(data[0]);
            this.userId = data[0]?.id;
            this.findExistingChat();
            this.conversationStarted = true;
            this.registeredUser = data[0];
          }
        });
      }
    });
  }
  ngOnDestroy() {
    if (this.subscribtion) {
      this.subscribtion.unsubscribe();
    }
  }

  get fullName() {
    return this.userForm.get('fullName');
  }

  get email() {
    return this.userForm.get('email');
  }

  get phone() {
    return this.userForm.get('phone');
  }

  openMessageContent() {
    if(this.isIconBtnActive){
      this.isFirstChatBox = false;
      this.isSecondChatBox = false;
      this.conversationStarted = false;
      console.log('a');
      localStorage.removeItem('isSecondChatBox');
      localStorage.removeItem('conversationStarted');
    }else {
      if(!this.isExistUser){
        console.log('b');
        this.isFirstChatBox = true;
      }else {
        console.log('c');
        this.isSecondChatBox = true;
        this.conversationStarted = true;
        localStorage.setItem('isSecondChatBox', String(this.isSecondChatBox));
        localStorage.setItem('conversationStarted', String(this.conversationStarted));
      }
      console.log('d');
    }

    this.scrollToBottom();

    this.isIconBtnActive = !this.isIconBtnActive;
    this.chatId = '';
  }
  showChatBox() {
    this.isFirstChatBox = false;
    this.isSecondChatBox = true;
    localStorage.setItem('isSecondChatBox', String(this.isSecondChatBox));
    // this.isShowChatBox = true;
    // this.isShow = true;
    this.scrollToBottom();
  }

  startConversation(event: Event) {

    event.preventDefault();

    if(this.userForm.valid){

      localStorage.removeItem('isSecondChatBox');

      this.db.collection('users', ref => ref.where('email', '==', this.userForm.value.email)).get().subscribe(snapshot => {
        if(snapshot.empty){
          this.db.collection('users').add({
            name: this.userForm.value.fullName,
            email: this.userForm.value.email,
            picture: null,
            phone: this.userForm.value.phone
          }).then(docRef => {
            this.userId = docRef.id;
            this.getUserInfo(docRef.id);
          })
        }else {
          this.userId = snapshot.docs[0].id;
          this.getUserInfo(snapshot.docs[0].id);
          this.findExistingChat();
        }
      })

      this.conversationStarted = true;
    }else {
      this.userForm.markAllAsTouched();
    }
  }

  getUserInfo(userId: string){
    this.db.collection('users').doc(userId).get().subscribe((res) => {
      if(res.exists){
        this.registeredUser = res.data();
      }else {
        console.log('user doesnt exist');
      }
    });
  }

  typeMessage(e: any) {
    this.newMessage = e.target.value;
  }

  onKeyDown(event: KeyboardEvent) {
    if(event.key === 'Enter'){
      this.startMessage();
    }
  }

  startMessage() {

    if(!this.chatId){
      console.log('a');
      this.createMessage();
    }else {
      console.log('b');
      this.addMessageToChat();
    }
  }

  findExistingChat() {
    this.db.collection('chats', ref => ref.where('userId', '==', this.userId))
      .get().subscribe(snapshot => {
      if(!snapshot.empty){
        this.chatId = snapshot.docs[0].id;
        this.loadMessages();
        this.isExistingChat = true;
        this.scrollToBottom();
      }else {
        this.isExistingChat = false;
      }
    })
  }

  loadMessages() {
    this.db.collection(`chats`).doc(this.chatId).valueChanges().subscribe((messages: any) => {
      if(messages?.adminId !== ''){
        this.getAdminInfo(messages?.adminId);
      }
      this.data = messages || [];
      this.scrollToBottom();
    })
  }

  getAdminInfo(adminId: string) {
    if(adminId !== null){
      this.db.collection('users').doc(adminId).valueChanges().subscribe((res) => {
        this.admin = res;
      });
    }
  }

  scrollToBottom(){
    setTimeout(() => {
      const chatContent = document.querySelector('.chat__content');
      if(chatContent){
        chatContent.scrollTop = chatContent.scrollHeight;
      }
    }, 0);
  }

  createMessage() {
    if(this.newMessage.trim() !== ''){
      this.db.collection('chats').add({
        userId: this.userId,
        lastMessageDate: Date.now(),
        adminId: null,
        unreadCount: 1,
        messages: [{
          text: this.newMessage,
          timeStamp: Date.now(),
          sender: 'user'
        }],
        userInfo: this.registeredUser
      }).then(docRef => {
        this.chatId = docRef.id;
        this.newMessage = '';
        this.findExistingChat();
      })
    }
  }

  addMessageToChat() {

    if(this.newMessage.trim() !== ''){
      this.db.collection('chats').doc(this.chatId).update({
        lastMessageDate: Date.now(),
        messages: firebase.firestore.FieldValue.arrayUnion({
          text: this.newMessage,
          timeStamp: Date.now(),
          sender: 'user'
        }),
        unreadCount: firebase.firestore.FieldValue.increment(1)
      }).then(() => {
        this.newMessage = ''
      })
    }
  }

}

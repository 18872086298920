<div class="main-gradient" style="height: {{height}}; margin-bottom: {{marginBottom}}">
  <div class="container" *ngIf="searchbar">
    <div class="row">
      <div class="col-md-3 mt-5">
        <img src="assets/images/main-search-left.svg" alt="Searchbar left image">
      </div>
      <div class="col-md-6">
        <h1>İstəyinə uyğun axtarış et, auksiona qoşul və <span>qalib ol!</span></h1>
        <div class="search">
          <button class="magnifier-button">
            <img src="./assets/images/magnifier.svg" alt="Magnifier">
          </button>
          <input type="text" placeholder="Axtarış et">
        </div>
      </div>
      <div class="col-md-3 mt-5">
        <img src="assets/images/main-search-right.svg" alt="Searchbar right image">
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>

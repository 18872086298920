<footer class="pt-4 pb-4">
  <div class="container">
    <div class="row mb-4">
      <div class="col-12 ps-0 d-flex justify-content-between">
        <img width="137px" src="/assets/images/logo-white.png" alt="Logo">
        <div class="d-flex align-items-center">
          <img src="./assets/images/mail-icon.svg" alt="Mail icon">
          &nbsp; <span class="text-white">info@udal.az</span>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <hr style="border-color: white">
    </div>
    <div class="row mb-4">
      <div class="col-4 ps-0">
        <ul class="ps-0">
          <li>Haqqımızda</li>
          <li>İstək cədvəli</li>
          <li>Qaliblər</li>
        </ul>
      </div>
      <div class="col-4">
        <ul class="ps-0">
          <li>Haqqımızda</li>
          <li>İstək cədvəli</li>
        </ul>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
</footer>
<div class="container-fluid bottom-footer">
  <div class="row">
    <div class="col-12">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex justify-content-between align-items-center pt-3 pb-3">
            <div>2022 Udal</div>
            <div>Bütün hüquqları qorunur</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

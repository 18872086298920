import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { authData } from '../../shared/helpers/auth.helper';
import {Subscription} from "rxjs";
import {IUsers} from "./users.model";
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private dbPath = 'users';
  private connectionSubscribtion: Subscription | null = null;
  constructor(private db: AngularFirestore, private auth: AngularFireAuth) { }
  createUser(profile: any) {

    // Check if user not exists in the database create if exists do nothing.
    // @ts-ignore
    this.connectionSubscribtion = this.db.collection('users', ref => ref.where('uid', '==', profile.id)).get().subscribe((data) => {
      if (data.empty) {
        delete profile['granted_scopes'];
        profile.uid = profile.id;
        profile.isActive = true;
        profile.role = 'user';
        profile.vendor = null;
        profile.createdAt = new Date();
        delete profile['id'];
        this.db.collection('users').add(profile).then();
      } else {
        // @ts-ignore
        this.db.collection('users').doc(data.docs[0].id).update({
          lastLoginAt: new Date(),
        }).then();
      }
      if (this.connectionSubscribtion) {
        this.connectionSubscribtion.unsubscribe()
      }
    });
  }

  getUsers(searchTerm: string = '', page: number = 1) {
    return this.db.collection<IUsers>(this.dbPath, ref => ref
      .where('isActive', '==', true)
      .where('name', '>=', searchTerm.toLowerCase())
      .where('name', '<=', searchTerm.toLowerCase()+ '\uf8ff')
    ).valueChanges({
      idField: 'id',
      limit: 10 * page,
    })
  }
  authUser() {
    return this.auth.user.subscribe((user) => {
      if (user) {
        this.db.collection<IUsers>(this.dbPath, ref => ref.where('email', '==', user.email)).valueChanges({
          idField: 'id',
          limit: 1,
        }).subscribe((data) => {
          if (data.length && data[0].id) {
            authData.next(data[0]);
          }
        });
      }
    });
  }
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'getal-main-searchbar',
  templateUrl: './main-searchbar.component.html',
  styleUrls: ['./main-searchbar.component.scss']
})
export class MainSearchbarComponent {
  @Input() height: string = 'auto';
  @Input() marginBottom: string = 'initial';
  @Input() searchbar: boolean = false;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import {AuthComponent} from "./auth.component";
import {FormsModule} from "@angular/forms";
import {LayoutsModule} from "../../shared/layouts/layouts.module";
import {SharedComponentsModule} from "../../shared/components/shared-components.module";

@NgModule({
  declarations: [
    AuthComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AuthRoutingModule,
    LayoutsModule,
    SharedComponentsModule
  ]
})
export class AuthModule { }

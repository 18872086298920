import {Component, OnInit} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {GoogleAuthProvider} from "firebase/auth";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'getal-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit{
  name: string = '';
  surname: string = '';
  email: string = '';
  password: string = '';
  errorMessage: string | null = null;
  public isRegister = false;
  height: number = 0;
  constructor(private auth: AngularFireAuth, private service: AuthService, private router: Router) {}
  ngOnInit() {
    this.height = window.innerHeight;
  }

  login() {
    this.auth.signInWithEmailAndPassword(this.email, this.password)
      .then((userCredential: any) => {
        this.service.createUser(userCredential?.additionalUserInfo?.profile)
        setTimeout(() => {
          this.navigate();
        } , 200)
      })
      .catch((error: any) => {
        // Error occurred during login
        console.error('Error Occured while logging in!')
        console.error(JSON.stringify(error));
      });
  }
  signWithPopup(){
    console.log('Popup')
    this.auth.signInWithPopup(new GoogleAuthProvider())
      .then((userCredential) => {
        // User successfully logged in
        this.service.createUser(userCredential?.additionalUserInfo?.profile)
        setTimeout(() => {
          this.navigate();
        }, 200)
      })
      .catch((error) => {
        // Error occurred during login
        console.error(JSON.stringify(error));
      });
  }
  register() {
    this.auth.createUserWithEmailAndPassword(this.email, this.password)
      .then((userCredential) => {
        // New user successfully registered
        console.log(userCredential);
        userCredential.user?.sendEmailVerification().then(() => {
          console.log('Email verification sent');
          alert('EMail Verification sent')
        })
          .catch((error) => {
            console.error('Failed to send email verification', error);
          });
      })
      .catch((error) => {
        // Error occurred during registration
        console.error(error);
      });
  }
  getUserCustomClaims() {

  }
  navigate() {
    this.router.navigate(['/profile'])
  }
}

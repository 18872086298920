import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {AuthModule} from "./pages/auth/auth.module";
import { adminGuard, profileGuard } from './shared/guards/admin.guard';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)},
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => AuthModule) },
  { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule) },
  { path: 'auctions', loadChildren: () => import('./pages/auctions/auctions.module').then(m => m.AuctionsModule), canActivate: [adminGuard] },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule), canActivate: [profileGuard] },
  { path: 'vendor', loadChildren: () => import('./pages/vendor/vendor.module').then(m => m.VendorModule) },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<section class="d-flex flex-column justify-content-between" style="min-height: {{height}}px">
  <section>
    <getal-header></getal-header>
    <getal-main-searchbar marginBottom="50px" *ngIf="!isRegister">
      <div class="container">
        <div class="row">
          <div class="offset-md-4 col-md-4 col-xs-12 auth-container d-flex flex-column">
            <div class="text-center">
              <img src="./assets/images/logo.svg" width="60px" class="auth-logo" alt="">
            </div>
            <div class="text-center login-text-header">
              Sistemə giriş
            </div>
            <form (submit)="login()">
              <div class="form-group">
                <label for="email">E-Poçt</label>
                <input type="text" [(ngModel)]="email" id="email" name="email" class="form-control">
              </div>
              <div class="form-group">
                <label for="password">Şifrə</label>
                <input type="text" id="password" [(ngModel)]="password" name="password" class="form-control">
              </div>
              <div class="form-group mt-4">
                <button class="btn btn-primary w-100">
                  Daxil ol
                </button>
              </div>
            </form>
            <div class="text-center another-auth">
              Və ya hesablarınla daxil ol
            </div>
            <div class="text-center third-party-auth d-flex align-items-center justify-content-center">
              <button (click)="signWithPopup()">
                <img width="100%" src="./assets/images/google.png" alt="google logo">
              </button>
            </div>
            <div class="register-link">
              <span>Hesabın yoxdur?</span><a (click)="isRegister = true">Qeydiyyatdan keç</a>
            </div>
          </div>
        </div>
      </div>
    </getal-main-searchbar>
  </section>
  <getal-main-searchbar marginBottom="50px" *ngIf="isRegister">
    <div class="container">
      <div class="row">
        <div class="offset-md-4 col-md-4 col-xs-12 auth-container d-flex flex-column">
          <div class="text-center">
            <img src="./assets/images/logo.svg" width="60px" class="auth-logo" alt="">
          </div>
          <div class="text-center login-text-header">
            Qeydiyyat
          </div>
          <form (submit)="register()">
            <div class="form-group">
              <label for="name">Ad</label>
              <input type="text" [(ngModel)]="name" id="name" name="name" class="form-control">
            </div>
            <div class="form-group">
              <label for="surname">Soyad</label>
              <input type="text" [(ngModel)]="surname" id="surname" name="surname" class="form-control">
            </div>
            <div class="form-group">
              <label for="email-register">E-Poçt</label>
              <input type="text" [(ngModel)]="email" id="email-register" name="email" class="form-control">
            </div>
            <div class="form-group">
              <label for="password-register">Şifrə</label>
              <input type="text" id="password-register" [(ngModel)]="password" name="password" class="form-control">
            </div>
            <div class="form-group mt-4">
              <button class="btn btn-primary w-100">
                Qeyd ol
              </button>
            </div>
          </form>
          <div class="text-center another-auth">
            Və ya hesablarınla daxil ol
          </div>
          <div class="text-center third-party-auth d-flex align-items-center justify-content-center">
            <button (click)="signWithPopup()">
              <img width="100%" src="./assets/images/google.png" alt="google logo">
            </button>
          </div>
          <div class="register-link">
            <span>Hesabın mövcuddur</span><a (click)="isRegister = false">Daxil ol</a>
          </div>
        </div>
      </div>
    </div>
  </getal-main-searchbar>

  <getal-footer></getal-footer>

</section>

<header>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <a href="/"><img src="/assets/images/logo.svg" width="100px" alt="logo" class="logo"></a>
      </div>
      <div class="col-md-4">
        <ul>
          <li>Qaliblər</li>
          <li>Haqqımızda</li>
          <li>İstək Cədvəli</li>
        </ul>
      </div>
      <div class="col-md-4">
        <a href="/vendor/auth" class="btn btn-primary me-3" >
          Satıcı portalı
        </a>
        <a href="/auth" class="btn btn-primary" *ngIf="!isLogged">
          Daxil ol
        </a>
        <a routerLink="/profile" *ngIf="isLogged" class="profile-details">
          {{loggedUser?.given_name}}<br>
          {{loggedUser?.family_name}}
        </a>
        <a *ngIf="isLogged" class="ms-3" (click)="logout()">
          <img src="./assets/images/log-out.svg">
        </a>
      </div>
    </div>
  </div>
</header>

 import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 import {ProductComponent} from "./product/product.component";
import { ProductItemComponent } from './product-item/product-item.component';
import { ProgressComponent } from './progress/progress.component';



@NgModule({
  declarations: [
    ProductComponent,
    ProductItemComponent,
    ProgressComponent
  ],
  exports: [
    ProductComponent,
    ProductItemComponent,
    ProgressComponent
  ],
  imports: [
    CommonModule
  ]
})
export class SharedComponentsModule { }

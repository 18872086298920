<div *ngIf="!isAdmin">
  <div *ngIf="isFirstChatBox" @fadeInOut  class="box">
    <div class="box__info">
      <h4 class="box__heading">Saytımıza xoş gəldiniz !</h4>
      <p class="box__txt">Tanış olmaqdan məmnunam! Xidmətlərimizlə bağlı hər hansı sualınız varsa, bizimlə əlaqə saxlamaqdan çekinmeyin.</p>
    </div>

    <div class="box__btn">
      <button (click)="showChatBox()">Bizə yazın</button>
    </div>
  </div>

  <div class="chat" @fadeInOut  *ngIf="isSecondChatBox">
    <div *ngIf="!conversationStarted" class="chat__start">
      <div class="chat__profile">
        <div>
          <img *ngIf="!this.data?.userInfo?.picture" src="./assets/images/business-man.jpg" alt="">
        </div>
        <p>Admin</p>
      </div>

      <div class="chat__info">
        <p>
          Onlayn konsultasiyaya xoş gəldiniz! Mənimlə dialoqa başlamaq üçün zəhmət olmasa forumu doldurun
        </p>
      </div>

      <div class="chat__form">
        <form [formGroup]="userForm" (ngSubmit)="startConversation($event)" novalidate>
          <div class="chat__group">
            <label for="fullName">Ad, Soyad: <sup class="chat__sup">*</sup></label>
            <input id="fullName" type="text" formControlName="fullName">
            <span *ngIf="fullName?.invalid && fullName?.touched" class="chat__error">Ad, Soyad qeyd edin</span>
          </div>

          <div class="chat__group">
            <label for="email">Email: <sup class="chat__sup">*</sup></label>
            <input id="email" type="email" formControlName="email">
            <span *ngIf="email?.invalid && email?.touched" class="chat__error">Email əlavə edin</span>
          </div>

          <div class="chat__group">
            <label for="phone">Əlaqə nömrəsi: <sup class="chat__sup">*</sup></label>
            <input id="phone" type="text" formControlName="phone" placeholder="XXX-XXX-XX-XX" mask="000-000-00-00">
            <span *ngIf="phone?.invalid && phone?.touched" class="chat__error">(XXX-XXX-XX-XX)</span>
          </div>

          <div class="chat__btn">
            <button type="submit">Bizə yazın</button>
          </div>

        </form>

        <!--        <div class="chat__btn">-->
        <!--          <button (click)="startConversation()">Start conversation</button>-->
        <!--        </div>-->
      </div>
    </div>

    <div *ngIf="conversationStarted" class="chat__frame">

      <div class="chat__profile">
        <div>
          <img *ngIf="!this.admin?.picture" src="./assets/images/business-man.jpg" alt="">
          <img *ngIf="this.admin?.picture" [src]="this.admin?.picture" alt="">
        </div>
        <p>Admin</p>
      </div>

      <div class="chat__content">
        <div *ngFor="let message of data?.messages" class="chat__messages" [ngClass]="message?.sender === 'user' ? 'chat__user' : 'chat__admin'">
          <p>{{message.text}}</p>
        </div>
      </div>

    </div>

    <div class="inp" @fadeInOut *ngIf="conversationStarted">
      <div class="inp__content">
        <input (keydown)="onKeyDown($event)" type="text" [(ngModel)]="newMessage" (input)=typeMessage($event) placeholder="Mətn">
        <img (click)="startMessage()" src="./assets/images/ui-send-icon.svg"  alt="">
      </div>
    </div>
  </div>

  <div class="mt-5 mb-5 content" (click)="openMessageContent()">
    <img *ngIf="!isIconBtnActive" class="content__message" src="assets/images/Chat_light.png" alt="">
    <img *ngIf="isIconBtnActive" class="content__close" src="assets/images/close-icon.png" alt="">
  </div>
</div>

<router-outlet></router-outlet>

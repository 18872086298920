import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';
import {AngularFireModule} from "@angular/fire/compat";
import {environment} from "../environments/environment";
import { AppRoutingModule } from './app-routing.module';
import {FormsModule} from "@angular/forms";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {AngularFirePerformanceModule, PerformanceMonitoringService} from "@angular/fire/compat/performance";
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirePerformanceModule,
    NgMultiSelectDropDownModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule
  ],
  providers: [
    ScreenTrackingService,UserTrackingService, PerformanceMonitoringService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
        logErrors: true,
        dialogOptions: {
          lang: 'az',
          title: 'Sistem xətası!',
          subtitle: 'Zəhmət olmasa, xətanı bildirin.',
          subtitle2: 'Xətanın səbəbi haqqında məlumat verin.',
        }
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  private dbPath = 'users';
  constructor() {
  }
}

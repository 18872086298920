import { CanActivateFn } from '@angular/router';
import { authData } from '../helpers/auth.helper';
import { filter, map, of } from 'rxjs';
import {IUsers} from "../../pages/auth/users.model";

export const adminGuard: CanActivateFn = (route, state) => {
  return authData.pipe(
    filter((user: IUsers | null): boolean => user !== null),
    map((user: IUsers | null) => !!user && !!user.role && user.role === 'admin'))
};
export const profileGuard: CanActivateFn = (route, state) => {
  return authData.pipe(
    filter((user: IUsers | null): boolean => user !== null),
    map((user: IUsers | null): boolean => (!!user && !!user.id) ? true : false));
};

export const environment = {
  firebase: {
    projectId: 'udal-63816',
    appId: '1:910516411905:web:c433f2f22e30b80c046a00',
    databaseURL: 'https://udal-63816-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'udal-63816.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyB_s9w2DbtnDtflPfeG0aFoaztNfnRwLyg',
    authDomain: 'udal-63816.firebaseapp.com',
    messagingSenderId: '910516411905',
    measurementId: 'G-9K585JXWG5',
  },
};

import { Component, OnInit } from '@angular/core';
import { authData } from '../../helpers/auth.helper';
import {IUsers} from "../../../pages/auth/users.model";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import Swal from "sweetalert2";

@Component({
  selector: 'getal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isLogged = false;
  loggedUser: IUsers | null = null;
  constructor(private auth: AngularFireAuth) { }

  ngOnInit(): void {
    authData.subscribe((data) => {
      if (data && data.id) {
        this.isLogged = true;
        this.loggedUser = data;
      }
    });
  }

  logout() {
    this.auth.signOut().then(signout => {
      authData.next(null);
      window.location.href = '/'
    })

  }
}
